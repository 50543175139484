
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900);

@font-face {
  font-family: LatoWeb;
  src: url(./assets/fonts/Lato/Lato-Regular.eot);
  src: url(./assets/fonts/Lato/Lato-Regular.eot?#iefix) format('embedded-opentype'), 
  url(./assets/fonts/Lato/Lato-Regular.woff2) format('woff2'), 
  url(./assets/fonts/Lato/Lato-Regular.woff) format('woff'), 
  url(./assets/fonts/Lato/Lato-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400
}
body {
  font-family: Lato!important;
  font-weight: 400
}