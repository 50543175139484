.trend-button-div {
    position: relative;
}

.trend-button-div .wrapper {
    position: relative;
}

.trend-button-div.orange .support-button {
    background: none #FDC83E;
    color: #000000 !important;
}

.trend-button-div .btn {
    width: auto;
    font-size: 16px;
    font-family: "Lato";
}

.trend-button-div .share-button.single-button + .dropdown {
    right: 50%;
}

.trend-button-div.orange .btn {
    text-transform: uppercase !important;
}

/* Above style */
.trend-button-div .dropdown {
    position: absolute;
    display: none;
    bottom: 75px;
    right: 14px;
    padding: 5px 14px;
    border-radius: 10px;
    width: 155px;
    background: #FFFFFF 0 0 no-repeat padding-box;
    border: 1px solid #909090;
    opacity: 0.64;
    z-index:100;
}

.trend-button-div .dropdown:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 11px solid #909090;
    position: absolute;
    bottom: -14px;
    right: 20px;
}

.trend-button-div .dropdown:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #FFFFFF;
    position: absolute;
    bottom: -11px;
    right: 20px;
}

.trend-button-div .dropdown.active {
    display: block;
}

.trend-button-div .shareButtonWrapper {
    height: 32px;
    text-align: left;
    font: normal normal bold 13px/16px Lato;
    letter-spacing: 0;
    color: #000000;
}

.trend-button-div .shareButton {
    outline: none;
    display: inline-block;
    height: 32px;
}

.trend-button-div .shareButtonWrapper img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    outline: none;
    display: inline-block;
}

.trend-button-div .shareFacebook {
    padding: 4px;
}

/* Below style */
.trend-button-div.bottom .dropdown {
    bottom: auto;
    right: -3px;
    top: 55px;
}

.trend-button-div.bottom .dropdown:before {
    border-top: none;
    border-bottom: 11px solid #909090;
    bottom: auto;
    right: auto;
    top: -11px;
    left: 20px;
}

.trend-button-div.bottom .dropdown:after {
    border-top: none;
    border-bottom: 11px solid #FFF;
    bottom: auto;
    right: auto;
    top: -10px;
    left: 20px;
}
