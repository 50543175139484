
   .image-slider {
    display: inline-block;
  }
  .tabs {
    width: 100%;
  }
  button:focus {
    outline: none;
    outline: none;
}
.dropdown-item:hover {
  background:#000 !important;
}
/* .eyeicon {
  width: 18px;
    cursor: pointer;
    position: absolute;
    right: 45px;
    margin-top: 15px;
} */
.navlinkcustome {
  font-size: 13px !important;
}
.toast-success {
  background-color: #51A351 !important;
}
.toast-error {
  background-color: #BD362F !important;
}
.paymt-result-div {
  text-align: center;
    background: rgb(255, 255, 255);
    margin-top: 30px;
    border-radius: 10px;
    padding-bottom: 80px;
    margin-bottom: 50px;
}
p {
  font-size: 15px;
  margin-bottom: 5px;
  color: #000;
  font-weight: 500;
  font-family: Lato!important;
}
.detailBanner{
  background-image: linear-gradient(to bottom, rgba(31, 26, 26, 0.5), #000000A3), url("../img/ngos/ngo-banner.png");
  background-position: bottom;
  padding-bottom: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
}
.counter-devider {
  border-right: 1px solid #c1c1c1;
  padding-top: 10px;
}
.logo-footer {
width: 225px;
}
.support-div-block {
 background: #F0F0F0;
 padding-bottom: 20px;
 padding-top: 35px;
}
.social-icon-adjust {
  float: right;
}
  .table-striped tbody tr:nth-of-type(even){
    background-color: rgba(0,0,0,.05);
  }
  .table-striped tbody tr:nth-of-type(odd){
    background-color: #fff;
  }
  .payment-success-icon {
    width: 65px;
    cursor: pointer;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .payment-modal-head1 {
    font-size: 35px;
    font-weight: bold;
    color:#0B0B0B
  }
  .links-faq {
    color: #f93131;
    font-weight: 500;
    border-bottom: 1px solid #f93131;
    margin-right: 10px;
    font-size: 14px;
  }
  .close {
    opacity: 1 !important;
  }
  .back-btn {
    padding: 7px 20px;
     color: #FDC83E;
     font-size: 13px;
     font-weight: 500;
     border: 1px solid #FDC83E;
     background: transparent;
     border-radius: 6px;
     height:40px;
     width: 100px;
  }
  .processing-fee {
    color: #d03939;
    padding-top: 40px;
    padding-bottom:20px;
    font-size: 16px;
  }
  .fontbold {
    font-weight: 600;
    cursor: pointer;
  }
  .tabOver {
    padding: 0px 50px 20px 50px;
    background: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
    margin-top: -292px;
  }
  .fundButtonTrue {
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #000;
    background-color: #FDC83E;
    border: none;
    border-radius: 6px;
    margin-right: 8px;
    margin-bottom:10px;
    width: 170px;
    height: 50px;
    box-shadow: 0px 3px 6px #ededed !important;
  }
  .fundButtonFalse {
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #ddd;
    border: none;
    border-radius: 6px;
    margin-right: 8px;
    margin-bottom:10px;
    width: 170px;
    height: 50px;
    box-shadow: 0px 3px 6px #ededed !important;
  }
  .buttonTrue {
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #000;
    background-color: #FDC83E;
    border: none;
    border-radius: 6px;
    margin-right: 8px;
    margin-bottom:10px;
    width: 170px;
    height: 50px;
    box-shadow: 0px 3px 6px #ededed !important;
  }
  .buttonFalse {
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #ddd;
    border: none;
    border-radius: 6px;
    margin-right: 8px;
    margin-bottom:10px;
    width: 170px;
    height: 50px;
    box-shadow: 0px 3px 6px #ededed !important;
  }
  .other-amount{
    width: 170px;
  }
  .btnAmt{
    width: 170px;
    box-shadow: 0px 3px 6px #ededed !important;
    cursor: pointer !important;
    margin-bottom: 10px;
    height: 40px;
    text-transform: none !important;
    border-radius: 6px !important;
    font-size:13px;
  }
  body:not(.modal-open){
    padding-right: 0px !important;
  }
  .scrolltotop {
    position: fixed;
    bottom: 10px;
    right: 10px;
    border: none;
    cursor: pointer;
    z-index: 1000;
  }
  .signupborder {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
  }
  .cat-img {
    padding-bottom: 15px;
  }
  .myTrt {
    color:#000;
    font-weight: bold;
  }
  .myTrt:hover {
    color:#000;
    font-weight: bold;
  }
  .btnAmt:hover, .btnAmt:focus, .btnAmt:active, .btnAmt.active, .btnAmt:active:focus, .btnAmt:active:hover, .btnAmt.active:focus, .btnAmt:not(:disabled):not(.disabled):active:focus, .btnAmt:not(:disabled):not(.disabled):active, .btnAmt:not(:disabled):not(.disabled).active, .btnAmt:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0px 3px 3px #FDC83E;
    background-color: #FDC83E ;
    border-color: #FDC83E !important;
    border-radius: 6px !important;
    cursor:pointer;
    color:#000;
    width: 170px;
    height: 40px;
    text-transform: none !important;
}
  .padtopbottom15 {
    padding-bottom: 15px;
    padding-top: 15px;
  }
.choosefile {
  float: right;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 4px;
  margin-bottom: 15px;
}
  .loadmorebtn {
    border-radius: 8px;
    width: 200px;
    margin-right: 10px;
    background: #fff;
    color: #000 !important;
    border: 1px solid #ddd;
    margin-bottom: 10px;
  }
  .card {
    box-shadow: 0 4px 7px -3px rgba(0, 0, 0, 0.15);
    border: 1px solid #fbfbfb;
  }

  .ngocard {
    border-radius: 12px;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #FFFFFF;
    color: #333333;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
    border: 1px solid #fbfbfb;
    transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
}

.payment-timer{
  font-size:14px;
  font-weight:bold;
}
.redirectText {
  font-size: 16px;
    font-weight: 500;
    padding-top: 18px;
    text-align: center;
}
.pop-blog-text{
  font-size: 14px;
  font-weight: bold;
  color: red;
  text-align:center;
}
.popContinue {
  text-align: center;
  padding-top: 30px;
}
  .image-container {
    vertical-align: middle;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .camp-slider-image{
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    cursor: pointer;
  }
  .errMsg {
    color: #cc0000;
      padding-top: 5px;
      float: right;
  }
  .image-container-trending {
    vertical-align: middle;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    text-align: center;
    padding-bottom: 5px;
  }
  .image-container-education {
    vertical-align: middle;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-bottom: 5px;
  }
  .plzEnsure {
    color: red;
    font-size: 19px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
  }
  .popup-blocker {
    padding: 5px;
    border: 2px solid red;
    border-radius: 40px;
    /* width: 68%; */
    cursor: pointer;
    text-align: center;
}
  .scroll-arrow {
    cursor:pointer;
    /* width: 40px; */
  }
  .plzWait {
    font-size: 16px;
    padding-top: 30px;
    color: gray;
    text-align:center;
  }
  .btnYes {
    width: 119px;
    cursor: pointer !important;
    height: 40px;
    text-transform: none !important;
    background: #FDC83E;
    color: #000;
    font-size: 16px;
    border: none;
  }
  .modal-text-size1 {
    font-size: 15px;
    font-weight: bold;
    padding-top: 20px;
    text-align: center;
    padding-bottom:16px;
  }
  .modal-text-size2 {
    font-size: 14px;
    font-weight: bold;
    padding-top: 10px;
    text-align: center;
  }
  .image-container-community {
    vertical-align: middle;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-bottom: 5px;
  }
  .modal-title {
    font-weight: bold;
    font-size: 20px;
    color: #000;
}
.modal-header {
  background: #FDC83E;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
  .image-container-disaster {
    vertical-align: middle;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-bottom: 5px;
  }
  .image-container-arts {
    vertical-align: middle;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-bottom: 5px;
  }
  .prev, .next {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 100%;
    cursor: pointer;
    color: black;
    transition: 0.6s ease;
  }
  input:focus{
    outline: none;
    background:#fff;
    border-radius:4px;
    border: 1px solid #000;
}
.other-amount:focus {
  background:#FDC83E;
  font-weight:bold;
  color: #000000;
}
  .next {
    margin-left: 5px;
  }

  .prev {
    margin-right: 5px;
  }
  .css-19ybaq8-control {
    background-color: #F0F0F0 !important;
  }
  .searchCamp {
    float: left;
    margin-left: 10px;
    border: none;
    height: 38px;
    border-bottom: 1px solid #00000039;
    background: #F0F0F0;
  }
  .searchCamp:focus {
    background-color: rgb(240, 240, 240);
    border-bottom: 1px solid #00000039;
    border-top:none;
    border-left:none;
    border-right:none;
  }

.searchCamp::-webkit-input-placeholder {
  font-family: FontAwesome;
  font-weight: normal;
  overflow: visible;
  vertical-align: top;
  display: inline-block !important;
  padding-left: 5px;
  padding-top: 2px;
  }

  .searchCamp::-moz-placeholder  {
  font-family: FontAwesome;
  font-weight: normal;
  overflow: visible;
  vertical-align: top;
  display: inline-block !important;
  padding-left: 5px;
  padding-top: 2px;
  }

  .searchCamp:-ms-input-placeholder  {
  font-family: FontAwesome;
  font-weight: normal;
  overflow: visible;
  vertical-align: top;
  display: inline-block !important;
  padding-left: 5px;
  padding-top: 2px;
  }
.searchIconStyle {
  width: 5%;
  margin-left: -405px;
  margin-top: 13px;
}
.fontAwesome {
  font-family:'FontAwesome';
}
  .prev:hover, .next:hover {
    color: white;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
  }
 .trend-data {
  background: #fff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 1px 2px 10px #00000039;
 }
 .margin-bottom-10{
  margin-bottom: 10px;
 }
.trend-home-title {
   white-space: initial;
   font-size: 20px;
   font-weight: bold;
   padding-top: 2px;
   padding-bottom: 3px;
   padding-left:15px;
 padding-right:15px;
 height: 48px;
 color:#000;
 line-height: 22px;
}
.trend-button-div {
  padding-bottom:15px;
  padding-left: 10px;
  padding-right: 10px;
}
.camp-detail-loc{
  padding-top: 5px;
    color: #999;
}
.table td {
  font-weight: 500;
  font-size: 12px;
  color:#000 !important;
}
.login-field {
  width: 100%;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    height: 40px;
    padding-left: 10px;
    font-size: 12px;
    color: #1a1a1a !important;
    font-weight: 500;
}
.password-eye {
  position: absolute;
  margin-top: 12px;
  right: 2.5em;
  cursor: pointer;
}
.title-login {
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 32px;
  color: #0b0b0b !important;
  font-weight: 600;
  font-size: 22px;
}
.withursocintwrk {
  font-size: 12px;
    color: #6e6e6e;
    font-weight: 600;
}
.weneverpost {
  font-size: 12px;
    color: #a7a7a7;
    text-align: center;
    padding-bottom: 12px;
}
.camp-category{
  font-size: 12px;
  color: #7cb4dd;
 padding-left:15px;
 padding-right:15px;
 padding-top:15px;
}
.camp-loc{
    font-size: 12px;
    padding-left:15px;
 padding-right:15px;
 color:#4d4d4d;
}
.camp-loc-percentage {
  font-size: 14px;
    padding-left:15px;
 padding-right:15px;
 color: #7b7b7b;
}
.camp-loc-label {
  font-size: 12px;
}
.camp-loc-detail{
  font-size: 12px;
color:#4d4d4d;
}
.camp-loc-detail-percentage {
font-size: 12px;
color: #7b7b7b;
}
.camp-loc-detail-label {
padding-left:0px;
font-size: 12px;
}
.tax-exempt-label {
  font-size: 10px;
  border: 1px solid #707070;
  padding: 2px 4px 3px 4px;
  margin-right: 5px;
  border-radius: 3px;
  height: 24px;
}
.tax-label-div {
  padding: 20px 10px 20px 15px;
 height: 20px;
}
.camp-amount {
    font-weight: bold;
    font-size: 13px;
    padding-right: 5px;
    color: #0a0a0a;
}
.support-button {
  border-radius: 9px;
  width: 56% !important;
  margin-right: 10px;
  background-image: linear-gradient(to right, #646464, #171717);
  color: #fff !important;
  border: none;
  text-transform: none !important;
  height: 52px;
}
.home-cate-title {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin-bottom: -2px;
}
.support-button-payment {
  border-radius: 8px;
  background: #FDC83E !important;
  color: #000 !important;
  border: none;
  height: 50px;
}
.register-termsofuse {
  font-size: 10px;
    margin-top: 10px;
}
.terms-signup {
  color: #398dff !important;
  font-weight: 500 !important;
  padding-left:2px;
  padding-right:2px;
}
.share-button {
    border-radius: 9px;
    width:38% !important;
    background: #fff;
    border: 1px solid #909090;
    color: #000;
    text-transform: none !important;
    height: 52px;
}
.share-button:hover {
  border-radius: 9px;
  width:38% !important;
  background: #fff;
  border: 1px solid #909090;
  color: #000;
  text-transform: none !important;
  height: 52px;
}
.share-button:focus {
  border-radius: 9px;
  width:38% !important;
  background: #fff;
  border: 1px solid #909090;
  color: #000;
  text-transform: none !important;
  height: 52px;
}
.progress-div {
  margin: 5px 15px 5px 15px;
}
.progress-div-detail {
  margin: 5px 0px 5px 0px;
}
.progress-bar-success {
  background-color: #7CB4DD;
}
.progress {
  height: 4px !important;
}
.btn-div {
  padding: 10px;
}
.support-div-top {
  background: #ffff;
  padding: 40px 30px 10px 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: -380px;
}
.tabs-home {
  /* background: #fff; */
    border-radius: 8px;
    padding: 15px 0px 0px 0px;
    margin-bottom: 20px;
}

.counter-cont-row {
  text-align: center;
  padding: 60px 0px 60px 0px;
    background: #fff;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    color: #000;
}
.faq-title {
  font-size: 16px;
  font-weight: bold;
  color: #222222;
  padding-bottom: 8px;
  padding-top:27px;
}
.tabs .tab {
	margin-top: 10px;
   color: #7b7474;
    text-align: center;
    display: inline-block;
    border-bottom: 1px solid rgb(204 198 198 / 20%);
    border-radius: 10px 10px 0 0;
    cursor: pointer;
	text-decoration: none;
  font-size: 14px;
  padding-left: 10px;
    padding-right: 10px;
  }
  .tabs .tab:hover {
	background: none;
  border-bottom: 1px solid rgb(204 198 198 / 20%);
  }
  .active {
    background: none;
    border-bottom: 3px solid #767676 !important;
    color: #000 !important;
  }
  .tabsContent {
    padding: 15px 0px 20px 0px;
    color: rgba(0,0,0,0.6);
  }
  .tab-head {
    text-align: center;
    color: #1e2225;
    margin: 5px 20px 20px 20px;
    font-size: 18px;
    font-weight: 600;
  }

  .btn-secondary {
    color: #000;
    background-color: #ddd;
    border-color: #6c757d;
    margin-right: 10px;
    border:none;
}
.card img {
  border-radius: 10px !important;
}
.select-payment-method {
  font-weight: 800;
    margin-top: 40px;
    margin-bottom: 10px;
    color:#000;
    font-size: 16px;
}
.card-register {
  background-color: #FFF;
  border-radius: 8px;
  color: #000;
  max-width: 350px;
  margin: 30px 0 70px;
  min-height: 400px;
  padding: 15px 30px 25px 30px;
}
.forgot-register {
  background-color: #FFF;
  border-radius: 8px;
  color: #000;
  max-width: 350px;
  margin: 70px 0 70px;
  padding: 15px 30px 18px 30px;
}
.home-counter-amount {
  font-size: 32px;
    font-weight: bold;
}
.register-form .form-control {
  border: 1px solid #eee !important;
  margin-bottom: 10px;
  font-size: 11px;
}
.login-facebook {
  width: 48%;
  background: #496cb3;
  color: #fff;
  font-size: 12px;
  height: 50px;
  border-radius: 6px;
}
.login-facebook:hover {
  width: 48%;
  background: #496cb3;
  color: #fff;
  font-size: 12px;
  height: 50px;
  border-radius: 6px;
}
.pclass{
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
  line-height: 0.1em;
  margin: 5px 0 20px;
}
.Orclass{
  background: #fff;
  padding: 0 10px;
  font-size: 14px;
  vertical-align: top;
  font-style: italic;
}
.forgotpasswd{
  float: left;
    color: #398dff;
    font-size: 10px;
    background: #fff;
    margin-top: 10px !important;
    padding-left: 0px;
    text-transform: none;
    font-weight: 500;
}
.forgotpasswd:hover {
  float: left;
    color: #398dff;
    font-size: 10px;
    background: #fff;
    margin-top: 10px !important;
    padding-left: 0px;
    text-transform: none;
}
.chekTerms {
  cursor: pointer;
    position: absolute;
    margin-top: 6px;
}
.recivupdates{
  padding-left: 22px;
    color: #696969;
    font-size: 12px;
}
.forgotLable {
  font-size: 14px;
    font-weight: bold;
}
.bysignup {
  color: #696969;
    font-size: 11px;
}
.signupasNgo {
  color: #4e90dd;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}
.getsupt {
  font-size: 12px;
    font-weight: 600;
}
.login-google {
  width: 48%;
  background: #fff;
  color: #000;
  font-size: 12px;
  margin-right: 0px;
  border: 1px solid #eee;
  height: 50px;
  border-radius: 6px;
}
.login-google:hover {
  width: 48%;
  background: #fff;
  color: #000;
  font-size: 12px;
  margin-right: 0px;
  border: 1px solid #eee;
  height: 50px;
  border-radius: 6px;
}
.social-icon-txt {
  margin-left: 8px;
    margin-top: 2px;
    position: absolute;
}
.social-icon-img-f {
  width: 18px;
    margin-left: -85px;
}
.social-icon-img-g {
  width: 15px;
    margin-left: -65px;
}
.ngo-and-community-txt {
  color: #fff;
  font-size:26px;
  font-weight:bold;
  padding-top:15px;
  text-shadow: 2px 2px #00000029;
}
.ngoandcommunitydiv {
  padding-top: 85px;
}
.home-counter-text {
  font-weight: 500;
    padding-top: 5px;
    padding-bottom: 10px;
}
.whyMaybankHeart{
  font-size:35px;
  font-weight:bold;
  color: #000;
}
.why-maybank-heart-yellow{
  background-image: url("../img/campaign/why-yellow-bg.svg");
  background-position: bottom;
  padding-bottom: 160px;
  background-repeat: no-repeat;
  background-size: cover;
}
.wan2helpngo{
  color: #FDC83E;
  font-size: 19px;
  font-weight: bold;
}
.referneedyNGO {
  color: #fff;
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 12px;
}
.writetous{
  background-image: linear-gradient(rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)), url(../img/ngos/faq-mh1.png);
  background-repeat: no-repeat;
  padding: 25px;
  height: 200px;
    border-radius: 6px;
    background-position: center;
}
.writetoneedy{
  background-image: linear-gradient(rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)), url(../img/ngos/faq-mh2.png);
  background-repeat: no-repeat;
  padding: 25px;
  height: 110px;
  margin-top:20px;
    border-radius: 6px;
    background-position: center;
}
.why-counter-text{
  font-size:16px;
  text-align: left;
    font-weight: 500;
    color: #000;
}
.why-counter-label{
  font-size:20px;
  font-weight:bold;
  color: #313131;
  padding-top: 20px;
    padding-bottom: 10px;
}
.ngoImgeSize{
  margin: 25% 23% 25% 23%;
}
.footer {
  background-color: #FBFBFB !important;
  line-height: 25px !important;
  box-shadow: 16px 0px 10px 0px rgba(0, 0, 0, 0.15);
}
.padleftright10 {
  padding-left: 10px;
  padding-right: 10px;
}
.ngoBanner {
  background-image: url("../img/ngo-banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 200px;
}
.faqBanner {
  background-image: url("../img/faq-banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 280px;
  margin-top: -75px;
}
.shareIcon {
  margin-left:5px;
  margin-bottom: 2px;
}
.recommendIcon {
  margin-left:5px;
  margin-bottom: 2px;
  width: 21px;
  height: 21px;
}
.recommend-button {
  border-radius: 9px;
  width: 13em !important;
  background: #fff;
  border: 1px solid #909090;
  color: #000;
  text-transform: none !important;
  padding: 5px 6px 5px 6px;
  height: 52px;
}
.recommend-button:hover {
  background: #fff;
  border: 1px solid #909090;
  color: #000;
  text-transform: none !important;
}
.recommend-button:focus {
  background: #fff;
  border: 1px solid #909090;
  color: #000;
  text-transform: none !important;
}
.camp-details-container {
  background: #fff;
    margin-top: 80px;
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 6px;
    position: relative;
    margin-top: -245px;
}
.camp-details-title-head {
  font-size: 16px;
    font-weight: bold;
    color: #222222;
    padding-bottom: 8px;
    padding-top:8px;
}
.camp-details-title-data {
  font-size: 16px;
  color: #222222;
  font-weight: normal !important;
}
.socialIcons {
  height: 38px;
  width: 38px;
margin-right: 10px;
}
.navbar .navbar-brand {
  padding: 0px 0px !important;
}
.navbar .navbar-nav .nav-item .nav-link {
  color: #000;
}
.SliderContainer .Navigator {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-top: -47px;
  z-index: 100;
  position: relative;
}
.home-banner-btn-icon{
  width: 30px;
    padding-left: 10px;
}
.home-banner-btn-icon-support{
  width: 38px;
    padding-left: 10px;
}
.footer-label-head {
font-weight: bold;
font-size:16px;
color:#000;
}
.footer-label {
font-size:14px;
cursor: pointer;
}
.footer-email-link {
  font-weight: bold;
  font-size: 12px;
  color: #4e90dd;
  border-bottom: 1px solid #4e90dd;
}
.supportCamHead {
 font-weight:bold;
 font-size: 26px;
 color: #0b0b0b;
}
@media (min-width:290px) and (max-width:767px) {
  .margnRt30{
    margin-right:30px;
  }
  .marg-bot-10-mobile{
    margin-bottom:10px;
  }
  .home-cate-title {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    margin-bottom: -2px;
  }
  .camp-details-title-head {
    font-size: 14px;
      font-weight: bold;
      color: #222222;
      padding-bottom: 8px;
      padding-top:8px;
  }
  .camp-details-title-data {
    font-size: 14px;
      color: #222222;
  }
  .image-slider {
    display: inline-block;
    width:100%;
  }
  .counter-devider {
    border-right: none;
    padding-top: 10px;
  }
  .logo-footer {
    width: 185px;
    }
  .why-maybank-heart-yellow{
    background-image:none;
    padding-bottom: 0px;
  }
  .why-counter-text{
    font-size:14px;
      font-weight: 500;
      text-align: center;
      color: #000;
  }
  .why-counter-label{
    font-size:18px;
    font-weight:bold;
    color: #313131;
    padding-top: 20px;
      padding-bottom: 10px;
  }
  .back-btn {
    padding: 5px 15px;
     color: #FDC83E;
     font-size: 13px;
     font-weight: 500;
     border: 1px solid #FDC83E;
     background: transparent;
     border-radius: 6px;
     height:40px;
     width: 100px;
  }
  .support-div-block {
    background: #F0F0F0;
    padding-bottom: 20px;
    padding-top: 20px;
   }
  .camp-details-container {
    background: #fff;
    margin-top: 0px;
      margin-bottom: 40px;
      padding: 20px;
      border-radius: 6px;
      position: relative;
      margin-top: -240px;
  }
  .ngo-and-community-txt {
    color: #fff;
    font-size:18px;
    font-weight:bold;
    padding-top:15px;
    text-shadow: 2px 2px #00000029;
  }
  .social-icon-adjust {
    float: none;
      padding-top: 10px;
  }
  .socialIcons {
    height: 24px;
    width: 31px;
  margin-right: 5px;
  }
  .support-button {
    border-radius: 9px;
    width: 55% !important;
    margin-right: 10px;
    background-image: linear-gradient(to right, #646464, #171717);
    color: #fff !important;
    border: none;
    text-transform: none !important;
    padding: 8px 6px 8px 6px;
    height: 52px;
  }
  .share-button {
    border-radius: 9px;
    width:36% !important;
    background: #fff;
    border: 1px solid #909090;
    color: #000;
    text-transform: none !important;
    padding: 8px 6px 8px 6px;
    height: 52px;
}
.share-button:hover {
  border-radius: 9px;
  width:36% !important;
  background: #fff;
  border: 1px solid #909090;
  color: #000;
  text-transform: none !important;
  padding: 8px 6px 8px 6px;
  height: 52px;
}
.share-button:focus {
  border-radius: 9px;
  width:36% !important;
  background: #fff;
  border: 1px solid #909090;
  color: #000;
  text-transform: none !important;
  padding: 8px 6px 8px 6px;
  height: 52px;
}
  .footer-email-box {
    border: 1px solid #707070;
    border-radius: 6px;
    padding: 12px 17px 12px 18px;
  }
  .camp-banner-img {
    position: absolute;
    height: 250px;
    width: 100%;
      }
  .ngoImgeSize{
    margin: 25% 25% 25% 28%;
  }
  .trend-button-div {
    padding-bottom:15px;
    text-align: center;
  }
  .support-div-top {
    background: #ffff;
    padding: 40px 30px 10px 50px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      margin-top: -380px;
  }
  /* .navbar-transparent .whiteLogo {
    display: inline !important;
    padding-left:10px;
  } */
  .tabOver {
    padding: 0px 30px 20px 30px;
    background: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .other-amount{
    width: 135px;
  }
  .buttonTrue {
    display: inline-block;
    font-size: 13px !important;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #000;
    background-color: #FDC83E;
    border: none;
    border-radius: 6px;
    margin-right: 8px;
    margin-bottom:10px;
    width: 135px;
    box-shadow: 0px 3px 6px #ededed !important;
  }
  .buttonFalse {
    display: inline-block;
    font-size: 13px !important;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #ddd;
    border: none;
    border-radius: 6px;
    margin-right: 8px;
    margin-bottom:10px;
    width: 135px;
    box-shadow: 0px 3px 6px #ededed !important;
  }
  .fundButtonTrue {
    display: inline-block;
    font-size: 13px !important;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #000;
    background-color: #FDC83E;
    border: none;
    border-radius: 6px;
    margin-right: 8px;
    margin-bottom:10px;
    width: 135px;
    box-shadow: 0px 3px 6px #ededed !important;
  }
  .fundButtonFalse {
    display: inline-block;
    font-size: 13px !important;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #ddd;
    border: none;
    border-radius: 6px;
    margin-right: 8px;
    margin-bottom:10px;
    width: 135px;
    box-shadow: 0px 3px 6px #ededed !important;
  }
  .table-responsive {
 width: 100%;
  }
  .supportCamHead {
    font-weight:bold;
    font-size: 18px;
    color: #0b0b0b;
   }
  .btnAmt{
    width: 95px;
    box-shadow: 0px 3px 6px #ededed !important;
    cursor: pointer !important;
    margin-bottom: 10px;
    height: 40px;
    text-transform: none !important;
    font-size: 10px;
    padding-top:12px;
  }
  .btnAmt:hover, .btnAmt:focus, .btnAmt:active, .btnAmt.active, .btnAmt:active:focus, .btnAmt:active:hover, .btnAmt.active:focus, .btnAmt:not(:disabled):not(.disabled):active:focus, .btnAmt:not(:disabled):not(.disabled):active, .btnAmt:not(:disabled):not(.disabled).active, .btnAmt:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0px 3px 3px #FDC83E;
    background-color: #FDC83E ;
    border-color: #FDC83E !important;
    border-radius: 6px !important;
    cursor:pointer;
    color:#000;
    width: 95px;
    height: 40px;
    text-transform: none !important;
    padding-top:12px;
}
  .camp-slider-image{
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    cursor: pointer;
    /* height:270px; */
  }
  .whyMaybankHeart{
    font-size:22px;
    font-weight:bold;
    color: #000;
  }
  .modal-dialog {
    max-width: 579px;
    margin: 1.75rem auto;
}
  .card-trend{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    margin-left: 9px;
    width: 100%;
  }
  .card-trend-home{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    margin-left: 9px;
    width: 323px;
  }
  .card-camp-home{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    width: 323px;
  }

  .banner-text-div {
    margin-top: 18%;
  }
  .deviceTop{
    background: #F0F0F0;
     padding-top: 10%;
}
.home-banner-title {
  font-size: 22px;
    font-weight: bold;
    color: #fff;
    line-height: 1.1;
    text-shadow: 2px 2px #00000029;
}
.home-banner-desc {
  font-size: 16px;
  color: #fff;
  padding-top: 20px;
    padding-bottom: 40px;
    text-shadow: 2px 2px #00000029;
}
.home-banner-learnmore-btn {
  border-radius: 8px;
    margin-right: 4px;
    width: 100%;
    font-size: 11px;
    background: #6960568f;
    border: none;
    height: 40px;
    padding-top: 10px;
}
.home-banner-watchvideo-btn {
  border-radius: 8px;
    margin-right: 4px;
    width:45%;
    font-size: 11px;
    background: #6960568f;
    color: #fff;
    border: none;
    height: 40px;
    padding-top: 10px;
}
.home-banner-support-btn {
  border-radius: 8px;
    margin-right: 4px;
    width: 100%;
    font-size: 11px;
    background: #FDC83E;
    border: none;
    color: #000;
    height: 40px;
    padding-top: 10px;
}
.home-banner-support-btn:hover {
  border-radius: 8px;
  margin-right: 4px;
  width: 100%;
  font-size: 11px;
  background: #FDC83E;
  border: none;
  color: #000;
  height: 40px;
  padding-top: 10px;
}
.home-banner-support-btn:focus {
  border-radius: 8px;
  margin-right: 4px;
  width: 100%;
  font-size: 11px;
  background: #FDC83E;
  border: none;
  color: #000;
  height: 40px;
  padding-top: 10px;
}
.home-banner-mytreats-btn {
  border-radius: 8px;
    margin-right: 4px;
    width:45%;
    font-size: 11px;
    height: 35px;
}
.section-heading {
  font-weight: bold;
  font-size: 20px;
  color: #000;
}
}
@media screen and (max-width: 767px) and (orientation:portrait) {
  .support-div-block {
    background: #F0F0F0;
    padding-bottom: 20px;
    padding-top: 20px;
   }
  .camp-details-container {
    background: #fff;
      margin-bottom: 40px;
      margin-top: 0px;
      padding: 20px;
      border-radius: 6px;
      position: relative;
      margin-top: -240px;
  }
  .ngo-and-community-txt {
    color: #fff;
    font-size:18px;
    font-weight:bold;
    padding-top:15px;
    text-shadow: 2px 2px #00000029;
  }
  .footer-email-box {
    border: 1px solid #b8b3b3;
    border-radius: 6px;
    padding: 10px;
  }
  .camp-banner-img {
    position: absolute;
    /* height: 545px;  */
    width: 100%;
      }
  .supportCamHead {
    font-weight:bold;
    font-size: 18px;
    color: #0b0b0b;
   }
  .table-responsive {
    width: 100%;
     }
  .trend-home-title {
    white-space: initial;
    font-size: 15px;
    font-weight: bold;
    padding-top: 2px;
    padding-bottom: 3px;
    padding-left: 15px;
    padding-right: 15px;
    height: 48px;
    color: #000;
    line-height: 22px;
}
  .modal-dialog {
    max-width: 579px;
    margin: 1.75rem auto;
}
  .card-trend{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    margin-left: 0px;
    width: 100%;
  }
  .card-trend-home{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    margin-left: 0px;
  }
  .card-camp-home{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    padding-left: 9px;
  }
  .banner-text-div {
    margin-top: 50%;
  }
  .deviceTop{
    background: #F0F0F0;
     padding-top: 11%;
}
.padleftright0 {
  padding-right: 22px;
}
.padleft8right0 {
  padding-left: 8px;
  padding-right: 0px;
  margin-left:10px;
}
.home-banner-learnmore-btn {
  border-radius: 8px;
    margin-right: 4px;
    width: 100%;
    font-size: 11px;
    background: #6960568f;
    border: none;
}
.home-banner-watchvideo-btn {
  border-radius: 8px;
    margin-right: 4px;
    width:45%;
    font-size: 11px;
    background: #6960568f;
    color: #fff;
    border: none;
}
.home-banner-support-btn {
  border-radius: 8px;
    margin-right: 4px;
    width: 100%;
    font-size: 11px;
    background: #FDC83E;
    border: none;
    color: #000;
}
.home-banner-support-btn:hover {
  border-radius: 8px;
    margin-right: 4px;
    width: 100%;
    font-size: 11px;
    background: #FDC83E;
    border: none;
    color: #000;
}
.home-banner-support-btn:focus {
  border-radius: 8px;
    margin-right: 4px;
    width: 100%;
    font-size: 11px;
    background: #FDC83E;
    border: none;
    color: #000;
}
.home-banner-mytreats-btn {
  border-radius: 8px;
    margin-right: 4px;
    width:45%;
    font-size: 11px;
    height: 35px;
}
.section-heading {
  font-weight: bold;
  font-size: 20px;
  color: #000;
}
}
@media screen and (max-width: 767px) and (orientation:landscape) {
  .logo-footer {
    width: 215px;
    }
  .why-maybank-heart-yellow{
    background-image:none;
    padding-bottom: 160px;
  }
  .why-counter-text{
    font-size:14px;
      font-weight: 500;
      text-align: center;
      color: #000;
  }
  .why-counter-label{
    font-size:18px;
    font-weight:bold;
    color: #313131;
    padding-top: 20px;
      padding-bottom: 10px;
  }
  .back-btn {
    padding: 5px 15px;
     color: #FDC83E;
     font-size: 13px;
     font-weight: 500;
     border: 1px solid #FDC83E;
     background: transparent;
     border-radius: 6px;
     height:40px;
     width: 100px;
  }
  .support-div-block {
    background: #F0F0F0;
    padding-bottom: 20px;
    padding-top: 30px;
   }
  .camp-details-container {
    background: #fff;
      margin-bottom: 40px;
      margin-top: 0px;
      padding: 20px;
      border-radius: 6px;
      position: relative;
      margin-top: -240px;
  }
  .ngo-and-community-txt {
    color: #fff;
    font-size:20px;
    font-weight:bold;
    padding-top:15px;
    text-shadow: 2px 2px #00000029;
  }
  .social-icon-adjust {
    float: right;
  }
  .footer-email-box {
    border: 1px solid #b8b3b3;
    border-radius: 6px;
    padding: 10px;
  }
  .camp-banner-img {
    position: absolute;
    /* height: 545px;  */
    width: 100%;
      }
  .ngoImgeSize{
    margin-left: 37%;
  }
  .support-div-top {
    background: #ffff;
    padding: 40px 30px 10px 50px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      margin-top: -380px;
  }
  .table-responsive {
    width: 440px;
     }
  .card-trend{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    margin-left: 9px;
  }
  .btnAmt{
    width: 120px;
    box-shadow: 0px 3px 6px #ededed !important;
    cursor: pointer !important;
    margin-bottom: 10px;
    height: 40px;
    text-transform: none !important;
    font-size: 10px;
  }
  .btnAmt:hover, .btnAmt:focus, .btnAmt:active, .btnAmt.active, .btnAmt:active:focus, .btnAmt:active:hover, .btnAmt.active:focus, .btnAmt:not(:disabled):not(.disabled):active:focus, .btnAmt:not(:disabled):not(.disabled):active, .btnAmt:not(:disabled):not(.disabled).active, .btnAmt:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0px 3px 3px #FDC83E;
    background-color: #FDC83E ;
    border-color: #FDC83E !important;
    border-radius: 6px !important;
    cursor:pointer;
    color:#000;
    width: 120px;
    height: 40px;
    text-transform: none !important;
}
  .card-trend-home{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    margin-left: 9px;
  }
  .card-camp-home{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    margin-left: 9px;
  }
  .banner-text-div {
    margin-top: 18%;
  }
  .deviceTop{
    background: #F0F0F0;
     padding-top: 25%;
}
.padleftright0 {
  padding-left: 8px;
  padding-right: 0px;
}
.padleft8right0 {
  padding-left: 8px;
  padding-right: 0px;
}
.home-banner-learnmore-btn {
  border-radius: 8px;
    margin-right: 4px;
    width: 100%;
    font-size: 11px;
    background: #6960568f;
    border: none;
}
.home-banner-watchvideo-btn {
  border-radius: 8px;
    margin-right: 4px;
    width:45%;
    font-size: 11px;
    background: #6960568f;
    color: #fff;
    border: none;
}
.home-banner-support-btn {
  border-radius: 8px;
    margin-right: 4px;
    width: 100%;
    font-size: 11px;
    background: #FDC83E;
    border: none;
    color: #000;
}
.home-banner-support-btn:hover {
  border-radius: 8px;
  margin-right: 4px;
  width: 100%;
  font-size: 11px;
  background: #FDC83E;
  border: none;
  color: #000;
}
.home-banner-support-btn:focus {
  border-radius: 8px;
  margin-right: 4px;
  width: 100%;
  font-size: 11px;
  background: #FDC83E;
  border: none;
  color: #000;
}
.home-banner-mytreats-btn {
  border-radius: 8px;
    margin-right: 4px;
    width:45%;
    font-size: 11px;
    height: 35px;
}
.section-heading {
  font-weight: bold;
  font-size: 18px;
  color: #000;
}
}
@media (min-width:768px) and (max-width:991px) {
  .tabOver {
    padding: 0px 50px 20px 50px;
    background: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
    margin-top: -380px;
  }
  .support-div-top {
    background: #ffff;
    padding: 40px 30px 10px 50px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      margin-top: -470px;
  }
  .camp-details-title-head {
    font-size: 14px;
      font-weight: bold;
      color: #222222;
      padding-bottom: 8px;
      padding-top:8px;
  }
  .camp-details-title-data {
    font-size: 14px;
      color: #222222;
  }
  .support-div-block {
    background: #F0F0F0;
    padding-bottom: 20px;
    padding-top: 100px;
   }
  .camp-details-container {
    background: #fff;
      margin-top: 80px;
      margin-bottom: 40px;
      padding: 20px;
      border-radius: 6px;
      position: relative;
      margin-top: -240px;
  }
  .ngo-and-community-txt {
    color: #fff;
    font-size:20px;
    font-weight:bold;
    padding-top:15px;
    text-shadow: 2px 2px #00000029;
  }
  .footer-email-box {
    border: 1px solid #b8b3b3;
    border-radius: 6px;
    padding: 10px;
  }
  .camp-banner-img {
    position: absolute;
   width:100%
      }
  .ngoImgeSize{
    margin: 10% 23% 10% 3%;
  }
  .support-button {
    border-radius: 9px;
    width: 150px !important;
    margin-right: 10px;
    background-image: linear-gradient(to right, #646464, #171717);
    color: #fff !important;
    border: none;
    text-transform: none !important;
    padding: 5px 6px 5px 6px;
    height: 52px;
  }
  .share-button {
    border-radius: 9px;
    width:150px !important;
    background: #fff;
    border: 1px solid #909090;
    color: #000;
    text-transform: none !important;
    padding: 5px 6px 5px 6px;
    height: 52px;
}
.share-button:hover {
  border-radius: 9px;
  width:150px !important;
  background: #fff;
  border: 1px solid #909090;
  color: #000;
  text-transform: none !important;
  padding: 5px 6px 5px 6px;
  height: 52px;
}
.share-button:focus {
  border-radius: 9px;
  width:150px !important;
  background: #fff;
  border: 1px solid #909090;
  color: #000;
  text-transform: none !important;
  padding: 5px 6px 5px 6px;
  height: 52px;
}
  .trend-button-div {
    padding-bottom:15px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
  .trend-home-title {
    white-space: initial;
    font-size: 15px;
    font-weight: bold;
    padding-top: 2px;
    padding-bottom: 3px;
    padding-left:15px;
  padding-right:15px;
  height: 70px;
  color:#000;
  line-height: 22px;
 }
  .modal-dialog {
    max-width: 579px;
    margin: 1.75rem auto;
}
  .card-trend{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    /* margin-left: 9px; */
    width: 210px;
  }
  .card-trend-home{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    width: 210px;
  }
  .card-camp-home{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    width: 210px;
  }
  .banner-text-div {
    margin-top: 33%;
  }
  .deviceTop{
    background: #F0F0F0;
     padding-top: 13%;
}
.home-banner-title {
  font-size: 30px;
    font-weight: bold;
    color: #fff;
    line-height: 1.1;
    text-shadow: 2px 2px #00000029;
}
.home-banner-desc {
  font-size: 16px;
  color: #fff;
  padding-top: 20px;
    padding-bottom: 40px;
    text-shadow: 2px 2px #00000029;
}
.padleftright0 {
  padding-left: 8px;
  padding-right: 0px;
}
.padleft8right0 {
  padding-left: 8px;
  padding-right: 0px;
}
.home-banner-learnmore-btn {
  border-radius: 8px;
    margin-right: 4px;
    width:100%;
    font-size: 9px;
    background: #6960568f;
    border: none;
    margin-bottom:5px;
}
.home-banner-watchvideo-btn {
  border-radius: 8px;
    margin-right: 4px;
    width:100%;
    font-size: 9px;
    background: #6960568f;
    color: #fff;
    border: none;
}
.home-banner-support-btn {
  border-radius: 8px;
    margin-right: 4px;
    width:100%;
    font-size: 9px;
    background: #FDC83E;
    border: none;
    color: #000;
    margin-bottom:5px;
}
.home-banner-support-btn:hover {
  border-radius: 8px;
    margin-right: 4px;
    width:100%;
    font-size: 11px;
    background: #FDC83E;
    border: none;
    color: #000;
    margin-bottom:5px;
}
.home-banner-support-btn:focus {
  border-radius: 8px;
  margin-right: 4px;
  width:100%;
  font-size: 11px;
  background: #FDC83E;
  border: none;
  color: #000;
  margin-bottom:5px;
}
.home-banner-mytreats-btn {
  border-radius: 8px;
    margin-right: 4px;
    width:100%;
    font-size: 11px;
    height: 35px;
}
.section-heading {
  font-weight: bold;
  font-size: 22px;
  color: #000;
}
}
@media (min-width:992px) and (max-width:1024px) {
  .footer-email-box {
    border: 1px solid #b8b3b3;
    border-radius: 6px;
    padding: 10px;
  }
  .camp-banner-img {
    position: absolute;
   width:100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0));
      }
  .ngoImgeSize{
    margin: 18% 23% 19% 17%;
  }
  .support-button {
    border-radius: 9px;
    width: 56% !important;
    margin-right: 10px;
    background-image: linear-gradient(to right, #646464, #171717);
    color: #fff !important;
    border: none;
    text-transform: none !important;
    height: 52px;
  }
  .trend-home-title {
    white-space: initial;
    font-size: 15px;
    font-weight: bold;
    padding-top: 2px;
    padding-bottom: 3px;
    padding-left:15px;
  padding-right:15px;
  height: 50px;
  color:#000;
  line-height: 22px;
 }
 .padleftright0 {
  padding-left: 8px;
  padding-right: 0px;
}
  .card-trend{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    /* margin-left: 9px; */
    width: 290px;
  }
  .card-trend-home{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    /* margin-left: 9px; */
    width: 290px;
  }
  .card-camp-home{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    /* margin-left: 9px; */
    width: 290px;
  }
  .banner-text-div {
    margin-top: 20%;
  }
  .deviceTop{
    background: #F0F0F0;
     padding-top: 8%;
}
.home-banner-title {
  font-size: 45px;
    font-weight: bold;
    color: #fff;
    line-height: 1.1;
    text-shadow: 2px 2px #00000029;
}
.home-banner-desc {
  font-size: 19px;
  color: #fff;
  padding-top: 20px;
    padding-bottom: 40px;
    text-shadow: 2px 2px #00000029;
}
.padleft8right0 {
  padding-left: 8px;
  padding-right: 0px;
}
.home-banner-learnmore-btn {
  border-radius: 8px;
    margin-right: 4px;
    font-size: 9px;
    width:100%;
    margin-bottom: 10px;
    background: #6960568f;
    border: none;
}
.home-banner-watchvideo-btn {
  border-radius: 8px;
    margin-right: 4px;
    font-size: 9px;
    width:100%;
    background: #FDC83E;
    background: #6960568f;
    color: #fff;
    border: none;
}
.home-banner-support-btn {
  border-radius: 8px;
    margin-right: 4px;
    font-size: 9px;
    background: #FDC83E;
    border: none;
    color: #000;
    width:100%;
    margin-bottom: 10px;
}
.home-banner-support-btn:hover {
  border-radius: 8px;
  margin-right: 4px;
  font-size: 11px;
  background: #FDC83E;
  border: none;
  color: #000;
  width:100%;
  margin-bottom: 10px;
}
.home-banner-support-btn:focus {
  border-radius: 8px;
  margin-right: 4px;
  font-size: 11px;
  background: #FDC83E;
  border: none;
  color: #000;
  width:100%;
  margin-bottom: 10px;
}
.home-banner-mytreats-btn {
  border-radius: 8px;
    margin-right: 4px;
    font-size: 11px;
    width:100%;
    margin-bottom: 10px;
    height: 35px;
}
.section-heading {
  font-weight: bold;
  font-size: 30px;
  color: #000;
}
}
@media (min-width:1025px) and (max-width:1399px) {
  .footer-email-box {
    border: 1px solid #b8b3b3;
    border-radius: 6px;
    padding: 10px;
    float: right;
  }
  .camp-banner-img {
    position: absolute;
    height: 545px;
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0));
      }
  .card-trend{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    margin-left: 9px;
    width: 352px;
  }
  .card-trend-home{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    margin-left: 9px;
    width: 352px;
  }
  .card-camp-home{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    margin-left: 9px;
    width: 352px;
  }
  .banner-text-div {
    margin-top: 20%;
  }
  .deviceTop{
    background: #F0F0F0;
     padding-top: 2%;
}
.home-banner-title {
  font-size: 45px;
    font-weight: bold;
    color: #fff;
    line-height: 1.1;
    text-shadow: 2px 2px #00000029;
}
.home-banner-desc {
  font-size: 18px;
  color: #fff;
  padding-top: 20px;
    padding-bottom: 40px;
    text-shadow: 2px 2px #00000029;
}
.padleftright0 {
  padding-left: 0px;
}
.padleft8right0 {
  padding-left: 29px;
    padding-right: 0px;
    margin-top: 2px;
}
.home-banner-learnmore-btn {
  border-radius: 8px;
    margin-right: 4px;
    font-size: 13px;
    background: #6960568f;
    border: none;
    width: 100%;
    height: 50px;
    padding-top: 10px;
}
.home-banner-watchvideo-btn {
  border-radius: 8px;
    margin-right: 4px;
    font-size: 11px;
    background: #6960568f;
    color: #fff;
    border: none;
    height: 50px;
    padding-top: 10px;
}
.home-banner-support-btn {
  border-radius: 8px;
    margin-right: 4px;
    font-size: 13px;
    background: #FDC83E;
    border: none;
    color: #000;
    padding-left:24px;
    width: 100%;
    height: 50px;
    padding-top: 10px;
}
.home-banner-support-btn:hover {
  border-radius: 8px;
    margin-right: 4px;
    font-size: 13px;
    background: #FDC83E;
    border: none;
    color: #000;
    padding-left:24px;
    height: 50px;
    padding-top: 10px;
}
.home-banner-support-btn:focus {
  border-radius: 8px;
  margin-right: 4px;
  font-size: 13px;
  background: #FDC83E;
  border: none;
  color: #000;
  padding-left:24px;
  height: 50px;
  padding-top: 10px;
}
.home-banner-mytreats-btn {
  border-radius: 8px;
    margin-right: 4px;
    font-size: 11px;
    height: 50px;
    padding-top: 10px;
}
.section-heading {
  font-weight: bold;
  font-size: 28px;
  color: #000;
}
}
@media only screen and (min-width:1400px) {
  .footer-email-box {
    border: 1px solid #b8b3b3;
    border-radius: 6px;
    padding: 10px;
    float: right;
  }
  .camp-banner-img {
    position: absolute;
    height: 545px;
    width: 100%;
      }
  .card-trend{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    margin-left: 9px;
    width: 352px;
  }
  .card-trend-home{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    margin-left: 9px;
    width: 352px;
  }
  .card-camp-home{
    display: inline-block;
    margin-right: 9px;
    text-align: left;
    margin-left: 9px;
    width: 352px;
  }
  .banner-text-div {
    margin-top: 15%;
  }
  .deviceTop{
    background: #F0F0F0;
     padding-top: 2%;
}
.home-banner-title {
  font-size: 45px;
    font-weight: bold;
    color: #fff;
    line-height: 1.1;
    text-shadow: 2px 2px #00000029;
}
.home-banner-desc {
  font-size: 18px;
  color: #fff;
  padding-top: 20px;
    padding-bottom: 40px;
    text-shadow: 2px 2px #00000029;
}
.padleftright0 {
  padding-left: 0px;
}
.padleft8right0 {
  padding-left: 29px;
    padding-right: 0px;
    margin-top: 2px;
}
.home-banner-learnmore-btn {
  border-radius: 8px;
    margin-right: 4px;
    font-size: 11px;
    background: #6960568f;
    border: none;
    width: 100%;
    height: 50px;
    padding-top: 10px;
}
.home-banner-watchvideo-btn {
  border-radius: 8px;
    margin-right: 4px;
    font-size: 11px;
    background: #6960568f;
    color: #fff;
    border: none;
    height: 50px;
    padding-top: 10px;
}
.home-banner-support-btn {
  border-radius: 8px;
    margin-right: 4px;
    font-size: 11px;
    background: #FDC83E;
    border: none;
    color: #000;
    padding-left:24px;
    width: 100%;
    height: 50px;
    padding-top: 10px;
}
.home-banner-support-btn:hover {
  border-radius: 8px;
    margin-right: 4px;
    font-size: 11px;
    background: #FDC83E;
    border: none;
    color: #000;
    padding-left:24px;
    height: 50px;
    padding-top: 10px;
}
.home-banner-support-btn:focus {
  border-radius: 8px;
    margin-right: 4px;
    font-size: 11px;
    background: #FDC83E;
    border: none;
    color: #000;
    padding-left:24px;
    height: 50px;
    padding-top: 10px;
}
.home-banner-mytreats-btn {
  border-radius: 8px;
    margin-right: 4px;
    font-size: 11px;
    height: 35px;
}
.section-heading {
  font-weight: bold;
  font-size: 28px;
  color: #000;
}
}
@media screen and (max-width: 991px){
.navbar-collapse .navbar-nav > .nav-item > .nav-link {
    margin: 0px 0px;
    color: #fff !important;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5em;
    padding: 15px 0px 10px 10px;
}
}
.navbar .navbar-collapse:after {
  background-color: #000 !important;
}
.nav-border-bottom {
  border-bottom: 2px solid #FDC83E;
  margin-top: 2px;
}
