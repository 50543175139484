$green: #93B50A

.SliderContainer
  width: 100%
  margin: 0 auto
  position: relative

  .SliderWrapper
    position: relative
    width: 100%

    .Slider
      position: relative
      height: 621px

      .Slide
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        opacity: 0
        z-index: 0
        transition: opacity 300ms ease-in
        background-position: center center
        background-size: cover

        &.active
          opacity: 1
          z-index: 2

        .SlideImage
          width: 100%
          height: 100%

    .ArrowKeys
      .Arrow
        z-index: 20
        position: absolute
        top: 0
        color: white
        background: none transparent
        height: 100%
        border: none
        font-size: 2rem
        outline: none

        &.ArrowLeft
          left: 0

        &.ArrowRight
          right: 0

  .Navigator
    display: flex
    align-items: center
    justify-items: center

    .NavigatorWrapper
      display: block
      margin: auto

      a
        display: inline-block
        font-size: 0
        margin: 2px 11px
        padding: 0
        outline: none
        transition: width 300ms ease-in
        width: 10px
        height: 10px
        border-radius: 5px
        background: none #FFF
        border: 2px solid #6767673B !important

        &.active
          width: 31px
